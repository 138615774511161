<!-- 公司销售报表 -->
<template>
  <div v-loading="loading" element-loading-text="数据较多，拼命加载中..." class="reportDetail-container">
    <breadcrumb :BreadcrumbData="BreadcrumbData"></breadcrumb>
    <!-- 筛选条件 -->
    <div class="common-screen-container">
      <div v-if="!$vuex.state.onlyCompany" class="common-input-container">
        <span>所属公司:</span>
        <el-select class="common-screen-input" @change="commonFun" v-model="params.companyId" placeholder="请选择公司" filterable>
          <el-option v-for="item in rolesCompany" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </div>
      <div v-if="recordType == 2 || recordType == 3 || recordType == 4" class="common-input-container">
        <span v-if="recordType == 2">销售负责人:</span>
        <span v-if="recordType == 3">质检人员:</span>
        <span v-if="recordType == 4">员工姓名:</span>
        <el-input class="common-screen-input" placeholder="请填写" v-model="params.keyword"></el-input>
      </div>

      <!-- <el-date-picker v-if="recordType == '4'" class="dateClassName" v-model="dateParams" @change="monthFun" type="monthrange" range-separator="~" start-placeholder="开始月份" end-placeholder="结束月份" clearable></el-date-picker> -->
      
      <el-button v-if="recordType == 2 || recordType == 3 || recordType == 4" class="common-screen-btn" type="primary" @click="() => {reportDetail()}">查 询</el-button>
      <el-button v-if="recordType == 2 || recordType == 3 || recordType == 4" class="common-screen-btn" plain @click="() => resetBtn()">重 置</el-button>

      <!-- <common-date v-if="recordType != '4'" @commonGetDate="commonGetDate" @dateTypeFun="dateTypeFun" :radio="2" :startDate="params.startDate" :endDate="params.endDate" ref="commonReset"></common-date> -->
      <common-date @commonGetDate="commonGetDate" @dateTypeFun="dateTypeFun" :radio="2" :startDate="params.startDate" :endDate="params.endDate" ref="commonReset"></common-date>
      
    </div>
    <!-- 导出 -->
    <el-button class="common-screen-btn" type="primary" @click="() => { exportBtn() }">导出报表</el-button>
    <el-button type="text" @click="() => { routeFun(`/custom-report/report-setting/${reportId}?backName=${reportName}&backUrl=/custom-report/report-detail/${reportId}&recordType=${recordType}&reportName=${reportName}&elementUuid=${elementUuid}`) }">报表设置</el-button>
    <!-- <el-button type="text" @click="() => { routeFun(`/custom-report/report-setting/${reportId}?backUrl=/custom-report/report-detail/${reportId}&reportName=${reportName}&elementUuid=${elementUuid}&recordType=${recordType}`) }">报表设置</el-button> -->
    <!-- 列表 -->
    <el-table class="common-table" :data="tableData" style="width: 100%" :fit="columnData && columnData.length > 8 ? false : true">
      <el-table-column prop="dataDate" :width="columnData && columnData.length > 8 ? '120px' : ''" :formatter="tableColumn" label="日期"></el-table-column>
      <el-table-column prop="companyName" :width="columnData && columnData.length > 8 ? '140px' : ''" :formatter="tableColumn" label="所属公司"></el-table-column>
      <template v-for="(item, index) in columnData">
        <el-table-column :key="index" :prop="item.fieldValue" :width="columnData && columnData.length > 8 ? widthFun(item.fieldName) : ''" :formatter="tableColumn" :label="item.fieldName" v-if="params.timeFlag == 'day' || (params.timeFlag != 'day' && item.timeType != 2)"> </el-table-column>
      </template>
      <!-- <el-table-column :prop="item.fieldValue" :width="columnData && columnData.length > 8 ? widthFun(item.fieldName) : ''"  :formatter="tableColumn" :label="item.fieldName" v-for="(item, index) in columnData" :key="index" v-if="params.timeFlag == 'day' || (params.timeFlag != 'day' && item.timeType != 2) "> </el-table-column> -->
    </el-table>
    <br/>
    <el-pagination
      @size-change="(e) => pageFun(e, 'pageSize')"
      @current-change="(e) => pageFun(e)"
      style="text-align: center"
      :page-sizes="pagination.pageSizes"
      :page-size="params.pageSize"
      :current-page="params.page"
      layout="total, prev, pager, next,sizes, jumper"
      :total="pagination.total"
    >
    </el-pagination>
  </div>
</template>

<script>
//import引入的组件需要注入到components中才能使用
import Breadcrumb from "../../components/common/breadcrumb.vue";
import { tableColumn, Config, DateTransform, getSectionDate, getLatelyDay } from "../../utils/index.js";
import CommonDate from "./children/commonDate.vue";
import { reportDetail, reportSetDetail } from "../../service/report.js"

export default {
  name: "reportDetail",
  components: {
    Breadcrumb,
    CommonDate
  },
  props: {},
  data() {
    return {
      tableColumn,
      Config,
      loading: false,
      BreadcrumbData: [
        { title: "首页", isLink: true, url: "/index" },
        { title: "数据", isLink: false },
        { title: "自定义报表", isLink: true, url: '/custom-report/report' },
        { title: this.$route.query.reportName, isLink: false }
      ],
      reportId: this.$route.params.id,
      reportName: this.$route.query.reportName,
      recordType: this.$route.query.recordType,
      dateParams: [],
      params: {
        page: 1,
        pageSize: 10,
        companyId: 0,
        timeFlag: 'day'
      },
      pagination: {
        total: 0,
        pageSizes: [5, 10, 20, 50, 100]
      },
      rolesCompany: this.$sto.get(Config.constants.userInfo).roles_company.filter((item) => { return item.id != 0 }),
      tableData: [],
      elementUuid: this.$route.query.elementUuid,
      columnData: []
    };
  },
  created() {
    
    if (this.rolesCompany && this.rolesCompany.length) {
      this.params.companyId = this.rolesCompany[0].id;
    }
    if (this.recordType == 4) {
      let dateMonth = getSectionDate('month')
      this.dateParams = dateMonth
      this.params.startDate = dateMonth[0]
      this.params.endDate = dateMonth[1]
    } else {
      let dateYestoday = getLatelyDay(0)
      this.params.startDate = DateTransform(dateYestoday[0])
      this.params.endDate =  DateTransform(dateYestoday[1])
    }
    this.reportSetDetail()
    this.reportDetail()
  },
  methods: {
    dateTypeFun(val) {
      if (val == "D") {
        this.params.timeFlag = 'day'
      } else {
        this.params.timeFlag = 'month'
      }
      // this.reportDetail()
    },
    async exportBtn() { // 导出按钮方法
      let columnData = [...this.columnData]
      
      let tHeader = ["日期", "所属公司"]
      let filterVal = ["dataDate", "companyName"]
      columnData.map((item) => {
        tHeader.push(item.fieldName)
        filterVal.push(item.fieldValue)
      })
      // this.exportExcel([{a: 1}], ['a'], ['b'])
      let params = {...this.params}
      params.recordId = this.reportId
      if (this.recordType == 4) {
        params.timeFlag = 'month'
      }
      params.page = 0
      params.pageSize = 0
      this.loading = true
      let { data } = await reportDetail(params)
      let sourceOriginAmount = data.list || []
      this.exportExcel(sourceOriginAmount, tHeader, filterVal)
    },
    exportExcel(sourceOriginAmount, tHeader, filterVal) { // sourceOriginAmount: 需要导出的数据； tHeader：导出excel 的标题； filterVal：每个标题对应的字段
      // let sourceOriginAmount = [ // 需要导出的数据，可以动态获取
      //   {goodsName: '苹果', sourceCode: '123'},
      //   {goodsName: '香蕉', sourceCode: '234'}
      // ]; 
      import('../../utils/Export2Excel.js').then(excel => { // 导入js模块
        // const tHeader = ['编号', '商品名称', '溯源码']; // 导出excel 的标题
        // const filterVal = ['index', 'goodsName', 'sourceCode']; // 每个标题对应的字段
        let list = sourceOriginAmount
        if (list) {
          const data = this.formatJson(filterVal, list); // 生成json数据
          // 调用excel方法生成表格
          excel.export_json_to_excel( tHeader, data, this.reportName );
        } else {
          this.$message.error('暂无数据')
        }
        this.loading = false;
      })
    },
    formatJson (filterVal, jsonData) {
        return jsonData.map(v => filterVal.map(j => v[j]));
    },
    widthFun(name) { // 改变表格列宽
      if (name.length > 20) {
        return '320px'
      } else if (name.length > 16) {
        return '280px'
      } else if (name.length > 12) {
        return '240px'
      } else if (name.length > 8) {
        return '160px'
      } else if (name.length > 4) {
        return '140px'
      }
      
    },
    async reportSetDetail() { // 获取表格列
      let { data } = await reportSetDetail({recordId: this.reportId})
      this.columnData = data
    },
    routeFun(url) {
      this.$router.push(url)
    },
    commonFun() {
      this.params.pageSize = 10;
      this.params.page = 1;
      this.reportDetail()
    },
    monthFun(val) {
      this.params.page = 1;
      this.params.startDate = DateTransform(val[0]);
      this.params.endDate = this.commonEndM(val[1]);
      this.reportDetail();
    },
    async reportDetail() { // 报表详情
      let params = {...this.params}
      params.recordId = this.reportId
      if (this.recordType == 4) {
        params.timeFlag = 'month'
      }
      this.loading = true
      let { data } = await reportDetail(params)
      this.loading = false
      this.tableData = data.list
      this.pagination.total = data.total
    },
    commonGetDate(startTime, endTime) {
      this.params.page = 1;
      this.params.startDate = startTime;
      this.params.endDate = endTime;
      this.reportDetail();
    },
    commonEndM (date) { // 月份最后一天
        let newDate = new Date(date)
        let newYear = newDate.getFullYear();
        let newMonth = newDate.getMonth() + 1
        let newDay = newDate.getDate();
        let nextDate = new Date(newYear, newMonth, newDay).getTime() - 1000 * 60 * 60 * 24
        return DateTransform(nextDate);
    },
    resetBtn() {
      this.params = {
        page: 1,
        pageSize: 10,
        companyId: this.rolesCompany[0].id,
        timeFlag: 'day'
      }
      this.$refs.commonReset.resetFun();
      this.reportDetail();
    },
    pageFun(e, type) {
      // 分页
      if (type == "pageSize") {
        this.params.pageSize = e;
      } else {
        this.params.page = e;
      }
      this.reportDetail();
    }
  }
};
</script>

<style lang="less" scoped>
.reportDetail-container {
  text-align: left;
}
</style>
